<template>
  <b-card no-body class="overflow-hidden">
    <b-card-header class="p-1">
      <h5 class="m-0">
        <strong class="text-primary">{{item.name}}</strong>
      </h5>
    </b-card-header>
    <b-card-body class="px-1 pb-1">
      <h6>
        <strong>{{item.mainCategory}} ( </strong>
        <small>{{item.subCategory}}</small>
        <strong> ) </strong>
      </h6>
        <b-img
          block
          height="140"
          rounded
          style="object-fit: cover;"
          class="w-100 my-1"
          alt="Image 1"
          :src="item.imageDto.path.replace('~', $store.getters['app/domainHost'])"
        ></b-img>
        <small>سعر المنتج : </small>
        <div class="d-flex justify-content-between pt-50">
          <strong>{{item.usdPrice}} $</strong>
          <strong dir="ltr">{{item.sypPrice}} SP</strong>
        </div>
    </b-card-body>
    <b-card-footer class="p-0">
      <b-button
        variant="primary"
        block
        squared
        :to="'/admin/main/products/details/' + item.id"
      >عرض التفاصيل</b-button>
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  components: {
  
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>